<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <h1
        class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
      >
        Bookmark Tickets
      </h1>

      <div class="mb-4">
        <!-- component -->
        <table id="tickets" class="border-collapse w-full text-sm">
          <thead>
            <tr>
              <th
                class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
              >
                Event
              </th>
              <th
                class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
              >
                Link
              </th>
              <th
                class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody id="ticketsList">
            <tr
              v-if="!data.length"
              class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
            >
              <td
                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                colspan="6"
              >
                {{ loading }}
              </td>
            </tr>
            <tr
              v-for="(tr, i) in data"
              :key="tr.ID"
              class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              id="tix-data-wait"
            >
              <td
                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
              >
                {{ tr.Event }}
              </td>
              <td
                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
              >
                <a
                  class="cursor-pointer text-blue-600"
                  :href="'/admin/ticket-chart/' + tr.Event"
                  target="_blank"
                >
                  View Graph/Chart</a
                >
              </td>
              <td
                class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
              >
                <div v-if="loadingRm === i">
                  Removing...
                </div>
                <div
                  v-if="loadingRm != i"
                  @click="remove(i, tr.ID)"
                  class="cursor-pointer text-blue-600"
                >
                  Remove
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "BookmarkTix",
  data() {
    return {
      loading: "Please wait...",
      data: [],
      loadingRm: -1
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$axios
        .get(`${this.$api}/bookmark/get`)
        .then(res => {
          this.data = res.data.data;
          if (this.data.length === 0) {
            this.loading = "No data found";
          }
          if (!res.data.success) {
            this.loading = "No data found";
          }
        })
        .catch(err => {
          console.log(err);
          this.loading = "Something went wrong, please try again";
        });
    },
    async remove(i, id) {
      try {
        this.loadingRm = i;
        const res = await this.$axios.delete(`${this.$api}/bookmark/remove`, {
          data: { id }
        });
        if (res.data.success) {
          let index = this.data.findIndex(v => v.ID == id);
          if (index > -1) {
            this.data.splice(index, 1);
          }
        }
        this.loadingRm = -1;
      } catch (e) {
        this.loadingRm = -1;
        console.log(e);
      }
    }
  }
};
</script>
